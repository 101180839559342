

















































import { Component, Vue } from 'vue-property-decorator';
@Component({
  components: {}
})
export default class P0242 extends Vue {}
